import React, { useRef, useState } from "react"
import { Accordion } from "react-bootstrap"
import { getLoginUserId } from "../../../services/auth"
import DriveTable from "../diveTable"
import AxiosInstance from "../../../services/axiosInstance"
import AddNewDropdown from "../addNewDropdown"
import DriveLazyLoad from "../driveLazyLoad"
import { onGrabData } from "../Service/driveListHelper"
import clsx from "clsx"
import { isMobileView } from "../../../services/globalHelpers"
import MobileAddNewDropdown from "../MobileView/addNewDropdown"

const FilesPanel = ({ driveAll, selectedDocId, showFilesPanel }) => {
  const triggerRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState("")
  const { data, loading } = DriveLazyLoad({
    triggerRef,
    onGrabData,
    selectedDocId,
    searchTerm,
    setSearchTerm,
  })

  const [uploading, setUploading] = useState(false),
    [selectedFile, setSelectedFile] = useState([]),
    [addDoc, setAddDoc] = useState(false),
    [addSequence, setSequence] = useState(false),
    [addCode, setAddCode] = useState(false),
    [addFolder, setAddFolder] = useState(false),
    [addForm, setAddForm] = useState(false)

  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click()
  }

  const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key])

  const [uploadingData, setUploadingData] = useState([])

  const handleNewFileUpload = e => {
    const { files: newFiles } = e.target
    const updatedFiles = newFiles
    let existingData = [...uploadingData]

    for (const updatedFile of updatedFiles) {
      existingData.push({ name: updatedFile.name })
    }
    setUploadingData(existingData)

    handleSubmit(convertNestedObjectToArray(newFiles))
  }

  const handleSubmit = newFiles => {
    return new Promise(async resolve => {
      await AxiosInstance.post(
        `/drive/file-upload`,
        {
          user_id: getLoginUserId(),
          drive_all_files: newFiles,
          parent_id: driveAll.id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(function (response) {
          if (response.status === 201) {
            console.log(response.status)
          }
          setUploading(false)
          window.location.reload()
          return response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  }

  return (
    <div className={`col-lg-12 mb-3${!showFilesPanel ? " d-none" : ""}`} id="files_panel">
      <Accordion defaultActiveKey="0" id="files_container">
        <Accordion.Item eventKey="0" className="panel panel-beige">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-paperclip" />
            </span>
            <span className="panel-heading-title">Files</span>
          </Accordion.Header>
          <Accordion.Body>
            {/*{uploading && (
              <div className="uploadFileLoading">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <span>Uploading</span>
                <span>
                  <img src={process.env.GATSBY_WRANGLER_URL + "StaticImages/thinking.gif"} alt="thinking" />
                </span>
              </div>
            )}*/}
            <div className="row">
              <div className="col-lg-12">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <AddNewDropdown driveAll={driveAll} />
                      <button type="button" className="btn btn-secondary btnUpload" onClick={onButtonClick}>
                        <i className="bi bi-cloud-arrow-up-fill" />{" "}
                        <span className="d-none d-md-inline">Upload</span>
                      </button>
                      <input
                        type="file"
                        className="d-none"
                        ref={inputFile}
                        onChange={handleNewFileUpload}
                        multiple
                      />
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="drive-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#drive"
                        role="tab"
                        aria-controls="drive"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Drive">
                          <i className="bi bi-database-fill" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="small-icon-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#small-icon"
                        role="tab"
                        aria-controls="small-icon"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Small Icons">
                          <i className="bi bi-ui-checks-grid" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="large-icon-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#large-icon"
                        role="tab"
                        aria-controls="large-icon"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" data-bs-title="Large Icons">
                          <i className="bi bi-stop-fill" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="View Templates"
                      >
                        <i className="bi bi-journals" />
                      </a>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Trash"
                      >
                        <i className="bi bi-trash-fill" />
                      </a>
                    </li>
                  </ul>

                  {/*mobile view*/}
                  {isMobileView() && (
                    <MobileAddNewDropdown
                      addDoc={addDoc}
                      addForm={addForm}
                      addSequence={addSequence}
                      addCode={addCode}
                      addFolder={addFolder}
                      uploadingData={uploadingData}
                      setUploadingData={setUploadingData}
                    />
                  )}
                </div>
              </div>
              <DriveTable
                data={data}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                parentId={selectedDocId}
                uploadingData={uploadingData}
              />
            </div>
            <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default FilesPanel
