import * as React from "react"
import Form from "react-bootstrap/Form"
import Moment from "react-moment"
import { useEffect, useState } from "react"
import { Link } from "gatsby"
import Dropdown from "react-bootstrap/Dropdown"

import { FOLDER_TYPES, PRIVATE_NOTE_ICON, privateNoteLabel, TYPE_PRIVATE_NOTE } from "./historyNoteHelper"
import { TYPE_CHAT_TO, TYPE_EMAIL, TYPE_HISTORY } from "./historyNoteHelper"
import AxiosInstance from "../../../../services/axiosInstance"

const HistoryNote = ({
  docName,
  driveAll,
  historyBlock,
  setHistoryBlock,
  historyNotes,
  setHistoryNotes,
  closeHistoryBlock,
  setCloseHistoryBlock,
}) => {
  const headerContainerHeight = 5,
    [historyType, setHistoryType] = useState("private_note"),
    [chatBlockHeight, setChatBlockHeight] = useState(window.innerHeight),
    [chatBlockTop, setChatBlockTop] = useState(headerContainerHeight),
    [historyBlockHeight, setHistoryBlockHeight] = useState(window.innerHeight),
    [updateHistoryBlock, setUpdateHistoryBlock] = useState(true),
    [historyBlockName, setUpdateHistoryName] = useState(privateNoteLabel()),
    [historyBlockIcon, setUpdateHistoryIcon] = useState(PRIVATE_NOTE_ICON),
    [notesTextArea, setNotesTextArea] = useState(""),
    [notesTextAreaError, setNotesTextAreaError] = useState(""),
    [addNoteArea, setAddNoteArea] = useState(false),
    [generateReport, setGenerateReport] = useState(false)

  const addNotes = () => {
    if (notesTextArea && driveAll.id) {
      setNotesTextAreaError("")

      return new Promise(async () => {
        await AxiosInstance.post(`/history-note/add`, {
          driveAllId: driveAll.id,
          content: notesTextArea,
          mode: "public",
          type: "note",
          status: true,
        })
          .then(function (response) {
            if (response.status === 201) {
              getHistoryNotes()
              setNotesTextArea("")
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              setNotesTextAreaError(error.response.data.message)
            } else {
              setNotesTextAreaError(error.response.data.detail)
            }
          })
      })
    }
  }

  const getHistoryNotes = () => {
    return new Promise(async () => {
      await AxiosInstance.get(`/drive/history-note/${driveAll.id}`)
        .then(function (response) {
          setHistoryNotes(response.data)
          return true
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setNotesTextAreaError(error.response.data.message)
          } else {
            setNotesTextAreaError(error.response.data.detail)
          }
        })
    })
  }

  const updateHistoryBlockHeight = () => {
    if (
      document &&
      document.querySelector(".ChatDockHeader") &&
      document.querySelector(".ChatDockFooterContainer")
    ) {
      setChatBlockHeight(window.innerHeight - headerContainerHeight)
      setChatBlockTop(headerContainerHeight)
      setHistoryBlockHeight(
        window.innerHeight -
          headerContainerHeight -
          document.querySelector(".ChatDockHeader").clientHeight -
          document.querySelector(".ChatDockFooterContainer").clientHeight
      )
    }
  }

  const showAddNote = () => {
    setAddNoteArea(true)
    setGenerateReport(false)
  }

  const showGenerateReport = () => {
    setAddNoteArea(false)
    setGenerateReport(true)
  }

  const toggleHistoryBlock = type => {
    setAddNoteArea(false)
    setGenerateReport(false)
    if (type === "report") {
      showGenerateReport()
    } else if (type === "private_note") {
      showAddNote()
    }
  }

  useEffect(() => {
    if (updateHistoryBlock) {
      if (driveAll.id) {
        getHistoryNotes()
        toggleHistoryBlock(TYPE_PRIVATE_NOTE)
      }
      setUpdateHistoryBlock(false)
    }

    let updateHistoryBlockHeightInterval = setInterval(() => {
      updateHistoryBlockHeight()
      clearInterval(updateHistoryBlockHeightInterval)
    }, 500)
  })

  return (
    <div className="col-lg-6">
      {!closeHistoryBlock && (
        <>
          <div className="chatDockSwitch">
            <input type="checkbox" id="chatDockSwitch" />
            <label htmlFor="chatDockSwitch" className="checkmark" />
          </div>
          <div className="SCChatDockNonSocialAssetOnly">
            <div className="ChatDockHeader">
              <div className="user_info">
                <span className="ChatDockHeaderAssetIcon">
                  <i className="bi bi-people-fill" />
                </span>
                {docName}
              </div>
              <div className="ChatDockInputOptContainer">
                <div className="dropdown ChatDockInputOpt">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      <i className={historyBlockIcon} />
                      &nbsp;{historyBlockName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ maxHeight: "250px", overflow: "auto" }}>
                      {FOLDER_TYPES.map((fieldOption, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={e => {
                              toggleHistoryBlock(fieldOption.type)
                              setUpdateHistoryName(fieldOption.label)
                              setUpdateHistoryIcon(fieldOption.icon)
                            }}
                            disabled={fieldOption.type === ""}
                          >
                            {fieldOption.type !== "" && (
                              <React.Fragment>
                                <i className={fieldOption.icon} />
                                &nbsp;&nbsp;
                              </React.Fragment>
                            )}
                            {fieldOption.label}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <a
                className="btnCloseChatDock"
                data-bs-toggle="tooltip"
                data-placement="left"
                data-animation="false"
                title="Close"
                onClick={() => {
                  setCloseHistoryBlock(true)
                }}
              >
                <i className="bi bi-x-lg" />
              </a>
            </div>

            <div
              className="ChatDockHistoryContainer"
              style={{
                position: "relative",
                top: "0",
                left: "0",
                overflow: "scroll",
                height: historyBlockHeight,
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <ul className="chat">
                {historyNotes !== null &&
                  historyNotes.length > 0 &&
                  historyNotes.map((historyData, historyIndex) => {
                    let chatClass = historyData.messageSent ? "self" : "other"
                    let userName = "swiftcloud"
                    let contactName = "swiftcloud"
                    return (
                      <>
                        {(historyData.content || historyData.title) && (
                          <li className={chatClass} key={historyIndex}>
                            <div className="chat-msg">
                              <div className="avatar">
                                <a
                                  href="#"
                                  className="quickcontact_userSelect d-block"
                                  data-bs-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title={userName}
                                >
                                  {/*<img src={imagePath} alt="" />*/}
                                  {chatClass === "self" && <i className="bi bi-people-fill" />}
                                </a>
                              </div>
                              <div className="msg">
                                {historyData.title && (
                                  <p className="subjectLine">
                                    <strong>{historyData.title}</strong>
                                  </p>
                                )}
                                <p dangerouslySetInnerHTML={{ __html: historyData.content }} />
                              </div>
                            </div>

                            <span className="msg-by-time">
                              <Moment format="MMM D, YYYY">{historyData.updatedAt}</Moment>&nbsp;
                              <span className="chat-msg-time-detail">
                                (<Moment format="dddd">{historyData.updatedAt}</Moment>) -&nbsp;
                                {historyData.type === TYPE_EMAIL && (
                                  <>
                                    <i className="bi bi-envelope-fill " />
                                    &nbsp; Email sent to ALL Contacts&nbsp;
                                  </>
                                )}
                                {historyData.type === TYPE_CHAT_TO ||
                                  (historyData.type === TYPE_HISTORY && (
                                    <>
                                      <i className="bi bi-chat-dots-fill " /> Chat by&nbsp;
                                      <Link to={`contact/detail/${historyData.contactId}`}>
                                        @{contactName}
                                      </Link>
                                      &nbsp;
                                    </>
                                  ))}
                                at &nbsp;
                                <Moment format="hh:mm a">{historyData.updatedAt}</Moment>
                              </span>
                            </span>
                          </li>
                        )}
                      </>
                    )
                  })}
              </ul>
            </div>

            <div className="ChatDockFooterContainer">
              {addNoteArea && (
                <div className="ChatDockOpt ChatDockPrivateNoteOpt">
                  <div className="mb-2">
                    <Form>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Add Note"
                        value={notesTextArea}
                        defaultValue={notesTextArea}
                        onChange={event => {
                          setNotesTextArea(event.target.value)
                        }}
                        onMouseLeave={event => {
                          setNotesTextArea(event.target.value)
                        }}
                      />
                      {notesTextAreaError && <div className="error-text">{notesTextAreaError}</div>}
                    </Form>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                      <a
                        data-bs-toggle="modal"
                        data-toggle="tooltip"
                        title=""
                        data-bs-original-title="Add URL"
                        aria-label="Add URL"
                      >
                        <i className="bi bi-link-45deg" />
                      </a>
                      <a
                        data-toggle="tooltip"
                        title=""
                        data-bs-original-title="Insert Image/Video"
                        aria-label="Insert Image/Video"
                      >
                        <i className="bi bi-camera-fill" />
                      </a>
                      <a
                        className="chatNoteImg"
                        data-toggle="tooltip"
                        title=""
                        data-bs-original-title="Upload file"
                        aria-label="Upload file"
                      >
                        <i className="bi bi-paperclip" />
                      </a>
                      <a
                        data-toggle="tooltip"
                        title=""
                        data-bs-original-title="Inject Emoji"
                        aria-label="Inject Emoji"
                      >
                        <i className="bi bi-emoji-smile" />
                      </a>
                      <input
                        type="file"
                        accept="image/*"
                        className="chatNoteImgFile"
                        style={{ visibility: "hidden", position: "absolute" }}
                      />
                    </div>
                    <div className="col-lg-6 text-end">
                      <button type="button" className="btn btn-primary" onClick={addNotes}>
                        <i className="bi bi-check2" />
                        Add Note
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {generateReport && (
                <div className="ChatDockOpt ChatDockReportOpt">
                  <div className="text-center py-3">
                    <button type="button" className="btn btn-primary">
                      <i className="bi bi-bar-chart-line-fill" />
                      Generate Report
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default HistoryNote
