import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import FolderDetail from "../../../components/Drive/Folder/folderDetail"
import ReduxWrapper from "../../../redux/reduxWrapper"

const Folder = props => {
  return <PrivateRoute component={FolderDetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<Folder {...props} />} />
export default WrappedPage
