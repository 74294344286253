import React from "react"
import { Form } from "react-bootstrap"
import { COMMENTS_VISIBILITY } from "../Service/moreOprtionsHelper"

const Comments = ({ docComments, setDocComments, commentVisibility, setCommentVisibility }) => {
  return (
    <div className="dh-block-body">
      Comments are
      <div className="toggleSwitch">
        <Form.Control
          type="checkbox"
          name="doc_comments"
          id="doc_comments"
          value={true}
          checked={docComments === "true" || docComments === true ? "checked" : ""}
          onChange={event => {
            setDocComments(event.target.checked)
          }}
        />
        <label></label>
      </div>
      {(docComments === "true" || docComments === true) && (
        <React.Fragment>
          {COMMENTS_VISIBILITY.map((cv, index) => {
            return (
              <div className="radio opt_comments" key={index}>
                <Form.Control
                  type="radio"
                  name="comments_visibility"
                  id={cv.id}
                  value={cv.value}
                  className="css-checkbox"
                  checked={commentVisibility === cv.value ? "checked" : ""}
                  onChange={event => {
                    setCommentVisibility(event.target.value)
                  }}
                />
                <label htmlFor={cv.id} className="css-radio-label">
                  {cv.label}
                </label>
              </div>
            )
          })}
        </React.Fragment>
      )}
    </div>
  )
}

export default Comments
