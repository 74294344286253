import * as React from "react"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"

import Layout from "../../Layout/layout"
import Seo from "../../seo"

import TaskPanel from "./TaskPanel"
import GroupMembers from "./GroupMembersPanel"
import Calendar from "./CalendarPanel"
import FilesPanel from "./FilesPanel"
import MoreOptionsPanel from "./MoreOptionsPanel"
import FolderChatPanel from "./folderChatPanel"
import FolderHeader from "./folderHeader"

import "../../../css/workroom.css"
import HistoryNote from "./HistoryNote/historyNote"
import AxiosInstance from "../../../services/axiosInstance"
import { isMobileView } from "../../../services/globalHelpers"
import PanelToggle from "../Item/panelToggle"

const FolderDetail = ({ selectedDocId }) => {
  const [closeHistoryBlock, setCloseHistoryBlock] = useState(isMobileView()),
    [driveAll, setDriveAll] = useState(null),
    [docName, setDocName] = useState(""),
    [ownerId, setOwnerId] = useState(""),
    [parentDoc, setParentDoc] = useState(""),
    [files, setFiles] = useState([]),
    [docPrivacy, setDocPrivacy] = useState(""),
    [whoCanModify, setWhoCanModify] = useState("JustMe"),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [privacyPassword, setPrivacyPassword] = useState(""),
    [whoCanCopy, setWhoCanCopy] = useState(""),
    [docComments, setDocComments] = useState(""),
    [commentVisibility, setCommentVisibility] = useState(""),
    [saveLoading, setSaveLoading] = useState(false),
    [historyNotes, setHistoryNotes] = useState([]),
    [historyBlock, setHistoryBlock] = useState(true)

  const [states, setStates] = useState({
    driveType: "folder",
    showRolesPanel: false,
    showFilesPanel: false,
    showMoreDetailsPanel: false,
  })

  const fillDocData = docDetail => {
    const driveAll = docDetail.driveAll
    setDriveAll(driveAll)
    setDocName(driveAll.nameDisplay)
    setOwnerId(driveAll.ownerID)
    setParentDoc(driveAll.parentID)
    setFiles(docDetail.subDirectories)
    setTempTags(driveAll.driveForm.moreOptions.doc_private_tags ?? [])
    setTags(driveAll.driveForm.moreOptions.doc_private_tags ?? [])
    setDocPrivacy(driveAll.driveForm.moreOptions.doc_privacy ?? "")
    setWhoCanModify(driveAll.driveForm.moreOptions.who_can_modify ?? "JustMe")
    setPrivacyPassword(driveAll.driveForm.moreOptions.privacy_password ?? "")
    setWhoCanCopy(driveAll.driveForm.moreOptions.who_can_copy ?? "")
    setDocComments(driveAll.driveForm.moreOptions.doc_comments ?? "")
    setCommentVisibility(driveAll.driveForm.moreOptions.comments_visibility ?? "")
    setHistoryNotes([])
    const metaJsonData = driveAll.driveForm.metaJson
    const metaJsonObj = { ...metaJsonData, driveType: "folder" }
    setStates(metaJsonObj)
  }

  const getDocData = async selectedDocId => {
    await AxiosInstance.get(`/drive/folder/${selectedDocId}`).then(response => {
      fillDocData(response.data)
    })
  }

  const submitDoc = e => {
    e.preventDefault()
    setSaveLoading(true)
    const formData = new FormData(e.target)
    let data = {
      userName: window.localStorage.getItem("userName"),
      name: formData.get("docName"),
      ownerID: formData.get("ownerID"),
      parent_id: parentDoc,
      form: {
        moreOptions: {
          doc_type: formData.get("docType"),
          doc_private_tags: tempTags,
          doc_privacy: formData.get("doc_privacy"),
          who_can_modify: formData.get("who_can_modify"),
          privacy_password: formData.get("privacy_password"),
          who_can_copy: formData.get("who_can_copy"),
          doc_comments: formData.get("doc_comments"),
          comments_visibility: formData.get("comments_visibility"),
        },
        metaJson: {
          showRolesPanel: states.showRolesPanel,
          showFilesPanel: states.showFilesPanel,
          showMoreDetailsPanel: states.showMoreDetailsPanel,
        },
      },
    }
    saveDocData(data)
  }

  const saveDocData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/folder/edit/${selectedDocId}`, data)
        .then(function (response) {
          setSaveLoading(false)
          console.log(response.data)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }

  useEffect(() => {
    getDocData(selectedDocId)
  }, [selectedDocId])

  return (
    <Layout>
      <Seo title={docName} />
      {driveAll && (
        <Form onSubmit={submitDoc} id={"docForm"}>
          <div id="workroomTemplate">
            <div id="right-section" className="h-100">
              <div className="row g-0">
                <div className="col" id="page-urls">
                  <div className="row">
                    <div className={`${closeHistoryBlock ? "col-lg-12" : "col-lg-6"}`}>
                      <FolderHeader docName={docName} ownerId={ownerId} parentDoc={parentDoc} />

                      <div className="row g-0">
                        <div className="col-lg-12 mb-3">
                          <div className="row g-0">
                            {/*Tasks & Projects*/}
                            {/*<TaskPanel></TaskPanel>*/}

                            {/*Group Members*/}
                            <GroupMembers driveAll={driveAll} showRolesPanel={states.showRolesPanel} />

                            {/*Calendar*/}
                            {/*<Calendar></Calendar>*/}

                            {/*Files*/}
                            <FilesPanel
                              driveAll={driveAll}
                              selectedDocId={selectedDocId}
                              showFilesPanel={states.showFilesPanel}
                            />

                            {/*More Options*/}
                            <MoreOptionsPanel
                              tags={tags}
                              setTags={setTags}
                              tempTags={tempTags}
                              setTempTags={setTempTags}
                              docPrivacy={docPrivacy}
                              setDocPrivacy={setDocPrivacy}
                              whoCanModify={whoCanModify}
                              setWhoCanModify={setWhoCanModify}
                              privacyPassword={privacyPassword}
                              whoCanCopy={whoCanCopy}
                              setWhoCanCopy={setWhoCanCopy}
                              docComments={docComments}
                              setDocComments={setDocComments}
                              commentVisibility={commentVisibility}
                              setCommentVisibility={setCommentVisibility}
                              showMoreDetailsPanel={states.showMoreDetailsPanel}
                            />

                            <PanelToggle states={states} setStates={setStates} />

                            <div className="col-lg-12 text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg"
                                disabled={saveLoading ? "disabled" : ""}
                              >
                                <i className="bi bi-check2" /> Save{" "}
                                <i className={saveLoading ? "spinner-border spinner-border-sm" : ""} />
                              </button>{" "}
                              {closeHistoryBlock && (
                                <button
                                  type="button"
                                  className="btn btn-default btn-lg btnOpenChatDock"
                                  data-bs-toggle="tooltip"
                                  title="History"
                                  onClick={() => setCloseHistoryBlock(false)}
                                >
                                  <i className="bi bi-clock-history" />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <HistoryNote
                      docName={docName}
                      driveAll={driveAll}
                      historyBlock={historyBlock}
                      setHistoryBlock={setHistoryBlock}
                      historyNotes={historyNotes}
                      setHistoryNotes={setHistoryNotes}
                      closeHistoryBlock={closeHistoryBlock}
                      setCloseHistoryBlock={setCloseHistoryBlock}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*Container Main end*/}
          </div>
        </Form>
      )}
    </Layout>
  )
}

export default FolderDetail
