import React from "react"
import { Accordion } from "react-bootstrap"

const Calendar = () => {
  return (
    <div className="col-lg-12 mb-3" id="calendar_panel">
      <Accordion defaultActiveKey="0" className="accordion-item panel panel-pink" alwaysOpen>
        <Accordion.Header>
          <i className="bi bi-calendar-check me-2" /> Calendar
        </Accordion.Header>
        <Accordion.Body>
          <div className="accordion-body">
            <div className="row g-0">
              <div className="col tableContainer">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="float-end">
                      <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                        <li className="nav-item">
                          <button type="button" className="btn btn-primary mt-1 me-1">
                            <i className="bi bi-plus" /> Add New
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="upcoming-event-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#upcoming-event-tab-pane"
                            role="tab"
                            aria-controls="upcoming-event-tab-pane"
                            aria-selected="false"
                          >
                            <span data-bs-toggle="tooltip" title="Upcoming Events">
                              <i className="bi bi-arrow-clockwise" />
                            </span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="past-event-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#past-event-tab-pane"
                            role="tab"
                            aria-controls="past-event-tab-pane"
                            aria-selected="false"
                          >
                            <span data-bs-toggle="tooltip" title="Past Events">
                              <i className="bi bi-arrow-counterclockwise" />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-12 tableFadeOut">
                    <div className="tab-content autoAdjustSearchBox" id="CalendarTabs">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming-event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="upcoming-event-tab"
                        tabIndex="0"
                      >
                        <table
                          id="tblUpcomingEvents"
                          className="table dataTable"
                          cellSpacing="0"
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th width="70%" className="nowrap">
                                Event
                              </th>
                              <th width="30%" className="d-none d-md-table-cell">
                                Scheduled
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Event 1</td>
                              <td className="d-none d-md-table-cell">
                                <time className="timeago" dateTime="2022-12-25"></time>
                              </td>
                            </tr>
                            <tr>
                              <td>Event 2</td>
                              <td className="d-none d-md-table-cell">
                                <time className="timeago" dateTime="2022-12-31"></time>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="past-event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="past-event-tab"
                        tabIndex="0"
                      >
                        <table id="tblPastEvents" className="table dataTable" cellSpacing="0" width="100%">
                          <thead>
                            <tr>
                              <th width="70%" className="nowrap">
                                Event
                              </th>
                              <th width="30%" className="d-none d-md-table-cell">
                                Scheduled
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Event 1</td>
                              <td className="d-none d-md-table-cell">
                                <time className="timeago" dateTime="2022-10-24"></time>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default Calendar
