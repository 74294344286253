import React from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import TimeAgo from "timeago-react"
import { Link } from "gatsby"

import { getFullName, getProfileImage } from "../../../services/settingsHelper"
import ContactQuickTools from "../../Contacts/Listing/contactQuickTools"
import TagListView from "../../Global/Tags/tagListView"
import { timeFormatter } from "../../../services/timeHelper"

const GroupMembers = ({ driveAll, showRolesPanel }) => {
  let profileImage = getProfileImage()
  if (!profileImage) {
    profileImage = "StaticImages/avatar.gif"
  }

  return (
    <div className={`col-lg-12 mb-3${!showRolesPanel ? " d-none" : ""}`} id="team_panel">
      <Accordion defaultActiveKey="0" className="accordion-item panel panel-green" alwaysOpen>
        <Accordion.Header>
          <span className="panel-heading-icon">
            <i className="bi bi-person-fill" />
          </span>
          <span className="panel-heading-title">People & Roles</span>
        </Accordion.Header>
        <Accordion.Body id="team_container">
          <div className="row btnAddNewPeopleInWorkroomContainer d-none">
            <div className="col-lg-12 mb-1">
              <div className="colHeading">
                <strong>
                  <i className="bi bi-person-plus-fill" /> Add Team User
                </strong>
              </div>
              <div className="dh-block-body">
                <div className="mb-2">
                  <input type="text" className="form-control" placeholder="Any email or phone" />
                </div>
                <div className="mb-2">
                  <select className="form-select">
                    <option>== List of roles ==</option>
                  </select>
                </div>
                <div className="mb-2">
                  <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle-fill" /> Whoa there! You don't have any roles
                    defined yet. Why not <a href="#">click here to add some now</a>?
                  </div>
                </div>
                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <input
                        type="checkbox"
                        name="sendInvitation"
                        id="sendInvitation"
                        className="css-checkbox"
                        defaultChecked
                      />
                      <label htmlFor="sendInvitation" className="css-checkbox-label">
                        Send Workroom Invitation
                      </label>
                    </div>
                    <div className="featuredContactBox" data-bs-toggle="tooltip" title="Featured Contact">
                      <input type="checkbox" name="featuredContact" id="featuredContact" />
                      <label htmlFor="featuredContact" />
                    </div>
                  </div>
                </div>
                <div className="mb-2 sendInvitationMsg">
                  <textarea className="form-control" placeholder="Optional: Add message..." />
                </div>
                <div className="text-end">
                  <button className="btn btn-default btnCloseAddNewPeopleInWorkroom">
                    <i className="bi bi-x-lg" /> Close
                  </button>
                  <button className="btn btn-primary">
                    <i className="bi bi-check2" /> Save & Invite <i className="bi bi-send-fill" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-1">
              <div className="float-end">
                <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                  <li className="nav-item">
                    <div className="btn-group btnAddNewDropdown m-0 peopleRolesAddNewDropdown" role="group">
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-plus-lg" />{" "}
                        <span className="d-none d-md-inline">Add to Workroom</span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <li>
                          <a className="dropdown-item btnAddNewPeopleInWorkroom" href="#">
                            <i className="bi bi-person-plus-fill" /> Contact
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-table" /> Upload CSV
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <table id="tblContacts" className="table dataTable" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th width="10%" className="tableColChkDel">
                      <label htmlFor="chkAll" className="css-checkbox-label" />
                    </th>
                    <th width="65%">Name </th>
                    <th width="25%" className="d-none d-md-table-cell">
                      Last Activity
                    </th>
                  </tr>
                  <tr className="d-none table_action_container">
                    <td colSpan="4" className="text-left">
                      <div className="batch-action-container">
                        <button className="btn btn-trash">
                          <i className="bi bi-trash-fill" /> Move to Trash
                        </button>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center tableColChkDel">
                      <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="C_ID1" className="css-checkbox" />
                        <label htmlFor="C_ID1" className="css-checkbox-label" />
                        <span className="tableColChkSpan btn-secondary">
                          <img
                            className="rounded-circle"
                            src={`${process.env.GATSBY_WRANGLER_URL}${profileImage}`}
                            alt=""
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href="#"
                        className="email_deliverable"
                        data-bs-toggle="tooltip"
                        title="emailaddr@domain.com - deliverable"
                      >
                        {getFullName()}
                        <i className="bi bi-check2" />
                      </a>
                      <span className="contactListCompany">SwiftCloud</span>
                    </td>
                    <td className="d-none d-lg-table-cell">
                      <div className="lastActivityBox">
                        <time className="timeago" dateTime="2022-05-01" />
                      </div>
                      <div className="statusActionBox">
                        <span className="workroomPeopleStarToggle">
                          <input type="checkbox" id="workroomPeopleStarToggle1" />
                          <label htmlFor="workroomPeopleStarToggle1" className="checkmark" />
                        </span>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Disconnect from Workroom</Tooltip>}>
                          <a href="#" className="btn btn-delete">
                            <i className="bi bi-person-dash" />
                          </a>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                  {driveAll?.driveWorkroom?.contacts.map(contact => {
                    let profilePicture = (
                      <i
                        className={`bi bi-${contact.contactType === "human" ? "person" : "building"}-fill`}
                      />
                    )
                    if (contact.profilePic) {
                      profilePicture = (
                        <img
                          src={process.env.GATSBY_WRANGLER_URL + contact.profilePic}
                          alt={contact.profilePic}
                        />
                      )
                    }
                    return (
                      <tr>
                        <td className="text-center tableColChkDel">
                          <div className="tableColChkDelBox">
                            <input type="checkbox" name="fileid[]" id={contact.id} className="css-checkbox" />
                            <label htmlFor={contact.id} className="css-checkbox-label" />
                            <span className="tableColChkSpan btn-secondary">{profilePicture}</span>
                          </div>
                        </td>
                        <td>
                          <Link to={`/contacts/detail/${contact.id}`} className="email_deliverable">
                            {contact.name}
                            <i className="bi bi-check2" />
                          </Link>
                          {contact.companyName && (
                            <span className="contactListCompany">{contact.companyName}</span>
                          )}
                          <ContactQuickTools contact={contact} />
                          {contact.tags.length > 0 && (
                            <TagListView tags={contact.tags} contactId={contact.id} />
                          )}
                        </td>
                        <td className="d-none d-lg-table-cell">
                          <div className="lastActivityBox">
                            <TimeAgo datetime={timeFormatter(contact.updatedAt)} />
                          </div>
                          <div className="statusActionBox">
                            <span className="workroomPeopleStarToggle">
                              <input type="checkbox" id="workroomPeopleStarToggle1" />
                              <label htmlFor="workroomPeopleStarToggle1" className="checkmark" />
                            </span>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Disconnect from Workroom</Tooltip>}
                            >
                              <a href="#" className="btn btn-delete">
                                <i className="bi bi-person-dash" />
                              </a>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default GroupMembers
