export const TYPE_HISTORY = "history"
export const TYPE_PRIVATE_NOTE = "private_note"
export const TYPE_CHAT_TO = "chatTo"
export const TYPE_REPORT = "report"
export const TYPE_EMAIL = "email"

export const PRIVATE_NOTE_ICON = "bi bi-sticky-fill"
export const privateNoteLabel = () => "Add Note"

export const FOLDER_TYPE_HISTORY = {
  type: TYPE_HISTORY,
  label: "History",
  icon: "bi bi-arrow-counterclockwise",
}

export const FOLDER_TYPES = [
  { ...FOLDER_TYPE_HISTORY },
  {
    type: TYPE_PRIVATE_NOTE,
    label: privateNoteLabel(),
    icon: PRIVATE_NOTE_ICON,
  },
  {
    type: TYPE_REPORT,
    label: "Generate Report",
    icon: "bi bi-graph-up",
  },
]
